import type { MenuProps } from "antd";
import i18n from "../localization";
import { TtripLog } from "../models";

type MenuItem = Required<MenuProps>["items"][number];

export const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
  // eslint-disable-next-line
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
};

export function translate(key: string) {
  return i18n.t(key);
}

export function changeLanguage(lang: string) {
  i18n.changeLanguage(lang);
}

export const classes = (...args: any) => {
  return args.join(" ");
};

export const isPlatformAdmin = (user) =>
  user.tenants.flatMap((tenant) => tenant.roles).includes("platform.admin");

export const mapToPos = (trackingResponse: TtripLog, type: string) =>
  Array.isArray(trackingResponse)
    ? trackingResponse.find((track) => track?.logType === type)?.location
    : null;
