import React, { Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import DashboardLayout from "../Layout/DashboardLayout";
import {
  AuthRoute,
  MainRoute,
  ProtectedRoute,
} from "../components/ProtectedRoute";
import { SettingsPage } from "../pages/SettingsPage";
import CreateUserBP from "../pages/CreateUserBP";

const HomePage = React.lazy(() => import("../pages/HomePage/HomePage"));

const Authentication = React.lazy(
  () => import("../pages/Authentication/Authentication")
);

const AddNewDriver = React.lazy(
  () => import("../pages/AddNewDriver/AddNewDriver")
);
const CreateCompany = React.lazy(
  () => import("../pages/CreateCompany/CreateCompany")
);
const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const ErrorElement = React.lazy(
  () => import("../pages/ErrorElement/ErrorElement")
);
const BluePrint = React.lazy(() => import("../pages/QuickOrder/QuickOrder"));
const Tracking = React.lazy(() => import("../pages/TrackingPage"));
export const router = createBrowserRouter([
  {
    index: true,
    path: "/",
    element: (
      <Suspense fallback="Loading please wait...">
        <MainRoute>
          <DashboardLayout />
        </MainRoute>
      </Suspense>
    ),
    errorElement: (
      <Suspense fallback="Loading please wait...">
        <ErrorElement />
      </Suspense>
    ),
  },
  {
    path: "/login",
    element: (
      <Suspense fallback="Loading Please Wait">
        <AuthRoute>
          <Authentication />
        </AuthRoute>
      </Suspense>
    ),
    errorElement: (
      <Suspense fallback="Loading Please Wait">
        <ErrorElement />
      </Suspense>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <Suspense fallback="Loading Please Wait">
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      </Suspense>
    ),
    errorElement: <ErrorElement />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback="Loading Please Wait">
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: "trips-and-orders/create-company",
        element: (
          <Suspense fallback="Loading Please Wait">
            <CreateCompany />
          </Suspense>
        ),
      },
      {
        path: "trips-and-orders/add-new-driver",
        element: (
          <Suspense fallback="Loading Please Wait">
            <AddNewDriver />
          </Suspense>
        ),
      },
      {
        path: "quick-order",
        element: (
          <Suspense fallback="Loading Please Wait">
            <BluePrint />
          </Suspense>
        ),
      },
      {
        path: "settings",
        element: (
          <Suspense fallback="Loading Please Wait">
            <SettingsPage />
          </Suspense>
        ),
      },
      {
        path: "tracking",
        element: (
          <Suspense fallback="Loading Please Wait">
            <Tracking />
          </Suspense>
        ),
      },
      {
        path: "users",
        element: (
          <Suspense fallback="Loading Please Wait">
            <CreateUserBP />
          </Suspense>
        ),
      },
    ],
  },
]);
