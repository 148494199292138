import { Button, Input, Layout } from "antd";
import { HambergerMenu, Notification, SearchNormal, Sms } from "iconsax-react";
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { IMAGES } from "../../assets";
import { RootState } from "../../state";
import { translate } from "../../utils";
import classes from "./AppHeader.module.scss";

const { Header } = Layout;

export type Props = {
  collapsed: boolean;
  noCollapse: boolean;
  setCollapsed: Dispatch<React.SetStateAction<boolean>>;
};

const AppHeader = ({ collapsed, noCollapse, setCollapsed }: Props) => {
  const { isDark } = useSelector((state: RootState) => state.settings);

  return (
    <Header className={classes.header}>
      {collapsed ? (
        <div className={classes.logoVerticalContainerCollapsed}>
          <img src={IMAGES.LOGO} alt="Digi mile" />
        </div>
      ) : (
        <div className={classes.logoVerticalContainer}>
          <img src={IMAGES.LOGO} alt="Digi mile" />
          <img src={IMAGES.DIGI_MILE_TEXT} alt="Digimile Text" />
        </div>
      )}
      {!noCollapse && (
        <Button
          type="text"
          onClick={() => setCollapsed(!collapsed)}
          className={classes.burgerButton}
          icon={<HambergerMenu size="20" color={isDark ? "#fff" : "#292d32"} />}
        />
      )}
      <Input
        size="middle"
        placeholder={translate("SEARCH_IN_DASHBOARD")}
        className={classes.searchInput}
        prefix={<SearchNormal className={classes.searchIcon} />}
        type="search"
      />
      <div className={classes.leftSideIconsContainer}>
        <div className={classes.bellIconContainer}>
          <Notification color="#292d32" />
        </div>
        <div className={classes.mailIconContainer}>
          <Sms color="#292d32" />
        </div>
        <div className={classes.profileImgContainer}>
          <img src={IMAGES.ADMIN_PHOTO} alt="userImage" />
        </div>
      </div>
    </Header>
  );
};
export default AppHeader;
