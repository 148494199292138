import { Form, Input } from "antd";
import { Mobile } from "iconsax-react";
import React from "react";
import { translate } from "../../../utils";

type PhoneFieldProps = {
  label: string;
  name: string;
  required?: boolean;
  message?: string;
  placeholder?: string;
};

const PhoneField: React.FC<PhoneFieldProps> = ({
  label,
  name,
  required,
  message,
  placeholder,
}) => {
  const [value, setValue] = React.useState();
  const onChange = (value: any) => {
    setValue(value);
  };

  const validatePhoneNumber = (_: any, value: any) => {
    if (
      !value ||
      value.length !== 11 ||
      value.charAt(0) !== "0" ||
      value.charAt(2) !== "2" ||
      value.charAt(2) !== "1" ||
      value.charAt(2) !== "5"
    ) {
      return Promise.reject(new Error("لا يمكن ادخال رقم هاتف غير صحيح"));
    }
    return Promise.resolve();
  };

  return (
    <Form.Item
      label={translate(label)}
      name={name}
      rules={[
        { required: required, message: message },
        // { validator: validatePhoneNumber },
      ]}
      style={{ width: "100%" }}
    >
      <Input
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        maxLength={11}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.target.value = e.target.value.replace(/[^0-9]/g, "");
        }}
        style={{
          width: "100%",
          maxWidth: "300px",
          height: "40px",
          backgroundColor: "transparent",
        }}
        suffix={<Mobile size="18" color="blue" />}
        inputMode="numeric"
      />
    </Form.Item>
  );
};

export default PhoneField;
