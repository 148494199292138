import { Button, Space, Switch } from "antd";
import { RootState } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../state/slices/language";
import i18n from "../../localization";
import { translate } from "../../utils";
import { toggleTheme } from "../../state/slices/settings";

const SettingsPage = () => {
  const { selectedLanguage } = useSelector(
    (state: RootState) => state.language
  );
  const { theme } = useSelector((state: RootState) => state.settings);

  const dispatch = useDispatch();

  const handleLanguageChange = (newLanguage: string) => {
    if (newLanguage === selectedLanguage && selectedLanguage === "en") {
      dispatch(setLanguage("ar"));
      i18n.changeLanguage("ar");
    } else {
      dispatch(setLanguage("en"));
      i18n.changeLanguage("en");
    }
  };

  const handleChangeTheme = () => {
    dispatch(toggleTheme());
  };

  return (
    <div className="SettingsPageWrapper">
      <Space align="center">
        <Button type="primary" onClick={() => handleLanguageChange("en")}>
          {translate("Change Language")}
        </Button>
        <Switch checked={theme === "dark"} onChange={handleChangeTheme} />
      </Space>
    </div>
  );
};

export default SettingsPage;
