import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IInitialState {
  user: any;
  loading: boolean;
  error: string;
  // user: IUser | undefined;
  location: {
    lat: number;
    lng: number;
  };
}

const initialState: IInitialState = {
  loading: false,
  user: null,
  error: "",
  location: { lng: 30, lat: 30 },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    clearUser(_) {
      return initialState;
    },
    setLoadingFalse(state) {
      state.loading = false;
    },
  },
});

export const { setUser, clearUser, setLoadingFalse } = userSlice.actions;
export default userSlice.reducer;
