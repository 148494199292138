import { App, ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";
import { router } from "./config/router";
import { LoadingProvider } from "./context";
import i18n from "./localization";
import { I18nextProvider } from "react-i18next";
import { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "./state";
import { COLORS } from "./utils";

const AppInitializer = () => {
  const { theme } = useSelector((state: RootState) => state.settings);
  useEffect(() => {
    document.body.setAttribute("data-theme", theme);
  }, [theme]);
  return (
    <Suspense fallback="Loading Please Wait">
      <App>
        <I18nextProvider i18n={i18n}>
          <ConfigProvider
            theme={{
              token: {
                // Seed Token
                colorPrimary: COLORS.CREULEAN_BLUE,
                borderRadius: 8,
                colorTextPlaceholder: "#666666CC",

                // Alias Token
                colorBgContainer: "#fff",
              },
            }}
          >
            <LoadingProvider>
              <RouterProvider router={router} />
            </LoadingProvider>
          </ConfigProvider>
        </I18nextProvider>
      </App>
    </Suspense>
  );
};

export default AppInitializer;
