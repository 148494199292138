import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required("ادخل البريد الالكتروني")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "برجاء التأكد من صيغة البريد الإلكتروني"
    ),
  password: Yup.string()
    .required("ادخل كلمة السر")
    .min(8, "كلمة المرور يجب أن لا تقل عن ٨ أحرف"),
});

const quickOrderSchemaOrderDetails = Yup.object().shape({
  customerId: Yup.number()
    .required("معرف العميل مطلوب")
    .positive("يجب عليك اختيار عميل")
    .integer("يجب عليك اختيار عميل"),
  partnerId: Yup.number().nullable().optional(),
  shipmentType: Yup.string().required("نوع الشحن مطلوب"),
  // fromLocation: Yup.number().required("معرف الموقع مطلوب"),
  // toLocation: Yup.number().required("معرف الموقع مطلوب"),
  //  fromLocation: Yup.object().shape({
  //   sequence: Yup.number().required("تسلسل الموقع مطلوب"),
  //   locationAction: Yup.string().required("إجراء الموقع مطلوب"),
  //   instructions: Yup.string().required("تعليمات الموقع مطلوبة"),
  //   locationName: Yup.string().required("اسم الموقع مطلوب"),
  //   longitude: Yup.number().required("خط الطول مطلوب"),
  //   latitude: Yup.number().required("خط العرض مطلوب"),
  //   locationType: Yup.string().required("نوع الموقع مطلوب"),
  // }),
  // toLocation: Yup.object().shape({
  //   sequence: Yup.number().required("تسلسل الموقع مطلوب"),
  //   locationAction: Yup.string().required("إجراء الموقع مطلوب"),
  //   instructions: Yup.string().required("تعليمات الموقع مطلوبة"),
  //   locationName: Yup.string().required("اسم الموقع مطلوب"),
  //   longitude: Yup.number().required("خط الطول مطلوب"),
  //   latitude: Yup.number().required("خط العرض مطلوب"),
  //   locationType: Yup.string().required("نوع الموقع مطلوب"),
  // }),
  ippingLineId: Yup.number().optional().nullable().positive("يجب أن يكون موجب"),
  shippingBookingNo: Yup.string().optional(),
  // placedDate: Yup.date().optional().nullable(),
  // placedDate: Yup.string().optional().nullable(),
  instructions: Yup.string().optional().nullable(),
});

const quickOrderSchemaDestination = Yup.object().shape({
  scheduledDate: Yup.date().required("تاريخ الجدولة مطلوب"),
  // status: Yup.string().required("الحالة مطلوبة"),
  containerType: Yup.string().required("نوع الحاوية مطلوب"),
  containerGrade: Yup.string().required("درجة الحاوية مطلوبة"),
  containerSize: Yup.string().required("حجم الحاوية مطلوب"),
  containerInstructions: Yup.string().required("تعليمات الحاوية مطلوبة"),
  // tripLocations: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       sequence: Yup.number().required("تسلسل الموقع مطلوب"),
  //       locationAction: Yup.string().required("إجراء الموقع مطلوب"),
  //       instructions: Yup.string().required("تعليمات الموقع مطلوبة"),
  //       locationName: Yup.string().required("اسم الموقع مطلوب"),
  //       longitude: Yup.number().required("خط الطول مطلوب"),
  //       latitude: Yup.number().required("خط العرض مطلوب"),
  //       locationType: Yup.string().required("نوع الموقع مطلوب"),
  //     })
  //   )
  //   .required("موقع واحد على الأقل مطلوب"),
});

const quickOrderSchemaTruckingDetails = Yup.object().shape({
  vendorId: Yup.string().required("معرف البائع مطلوب"),
  driverId: Yup.string().required("معرف السائق مطلوب"),
  // driverName: Yup.string().required("اسم السائق مطلوب"),
  // driverMobileNo: Yup.string().required("رقم هاتف السائق مطلوب"),
  vehicleNo: Yup.string().required("رقم السيارة مطلوب"),
});

export const quickOrderSchema = [
  quickOrderSchemaOrderDetails,
  quickOrderSchemaDestination,
  quickOrderSchemaTruckingDetails,
];
