import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "./en.json";
import arTranslations from "./ar.json";

// .use(LanguageDetector)
i18n.use(initReactI18next).init({
  resources: {
    ar: { translation: arTranslations },
    en: { translation: enTranslations },
  },
  lng: "ar",
  fallbackLng: "ar",
  debug: false, // set to false during production
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
