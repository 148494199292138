import { Form, Select } from "antd";
import React, { useState } from "react";
import { translate } from "../../../utils";
import "./CustomSelectField.scss";
interface IOption {
  value: any;
  label: string;
}

interface CustomSelectInputProps {
  label?: string;
  name: string;
  options: any;
  required?: boolean;
  message?: string;
  placeholder?: string;
  className?: string;
  suffixIcon?: React.ReactNode;
  [key: string]: any;
  loading?: boolean;
}

const CustomSelectField: React.FC<CustomSelectInputProps> = ({
  label,
  name,
  options,
  required = false,
  placeholder,
  message,
  className,
  suffixIcon,
  loading,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (value: any) => {
    setSelectedValue(value);
  };

  function stringifyIfNeeded(value: any) {
    if (typeof value === "object" && value !== null) {
      return JSON.stringify(value);
    } else if (typeof value === "string") {
      return value;
    } else {
      return value;
    }
  }

  return (
    <Form.Item
      label={label && translate(label)}
      name={name}
      rules={[{ required: required, message: message }]}
      className={`${className} select-input-container`}
    >
      <Select
        placeholder={translate(placeholder as string)}
        onChange={handleChange}
        style={{ maxWidth: "100%", height: "40px" }}
        suffixIcon={suffixIcon}
        loading={loading}
        value={selectedValue}
      >
        {options.map((option: any) => (
          <Select.Option
            className="option"
            key={option.label}
            value={stringifyIfNeeded(option.value)}
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default CustomSelectField;
