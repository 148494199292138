import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: "light",
  isDark: false,
  menuCollapsed: false,
};

const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleTheme(state) {
      state.theme = state.theme === "light" ? "dark" : "light";
      document.body.setAttribute(
        "data-theme",
        state.theme === "dark" ? "dark" : "light"
      );
      state.isDark = state.theme === "dark";
    },
    toggleCollapseMenu(state) {
      state.menuCollapsed = !state.menuCollapsed;
    },
  },
});

export const { toggleTheme, toggleCollapseMenu } = settings.actions;

export default settings.reducer;
