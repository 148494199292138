import React from "react";
import { Typography } from "antd";
import classes from "./FormInputSection.module.scss";

type Props = {
  step: number;
  title: string;
  children?: React.ReactNode;
};
const FormInputSection = ({ step, title, children }: Props) => {
  return (
    <>
      <div className={classes.container}>
        <span className={classes.step}>{step}</span>
        <Typography className={classes.title}>{title}</Typography>
      </div>
      {children}
    </>
  );
};

export default FormInputSection;
