import { Col, Form, Radio, RadioChangeEvent } from "antd";
import { Field, FieldProps } from "formik";
import { ReactNode, useState } from "react";
import TextError from "../TextError/TextError";
import "./CustomRadioGroup.scss";
import { translate } from "../../../utils";

interface RadioOption {
  value: string;
  label: string;
}

interface RadioGroupProps {
  label: string;
  name: string;
  options: RadioOption[];
  children?: ReactNode;
  required?: boolean;
  message?: string;
  change?: (e: RadioChangeEvent) => void;
}

const CustomRadioGroup: React.FC<RadioGroupProps> = ({
  name,
  options,
  label,
  required,
  message,
  change,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedValue(e.target.value);
  };

  const getColGutter = () => {
    if (
      name === "containerType" ||
      name === "containerGrade" ||
      name === "containerSize"
    ) {
      return 8;
    } else {
      return 24;
    }
  };

  return (
    // <Col md={getColGutter()} xs={getColGutter()} xl={12}>
    <Form.Item
      label={translate(label)}
      rules={[
        {
          required: required,
          message: message,
        },
      ]}
      name={name}
    >
      <Radio.Group
        id={name}
        optionType="button"
        buttonStyle="solid"
        onChange={change ? change : handleRadioChange}
        value={selectedValue}
        className="customRadioGroup"
      >
        {options.map((option) => (
          <Radio key={option.value} value={option.value} >
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
    //</Col>
  );
};

export default CustomRadioGroup;
