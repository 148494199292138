import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from "firebase/auth";
import { firebaseConfig } from "../config";
import { message } from "antd";
import { translate } from "../utils";

const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

export const handleSignIn = async (email: string, password: string) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    const firebaseIdToken = await result.user.getIdToken();
    return { code: "SUCCESS", tokenId: firebaseIdToken };
  } catch (error: any) {
    return Promise.reject({ code: error.code });
  }
};

export const handleSignOut = async () => {
  try {
    await auth.signOut();
    message.success(translate("LOGOUT_SUCCESS"));
  } catch (error) {
    console.log(error);
  }
};

export const passwordReset = async (email: string) => {
  return await sendPasswordResetEmail(auth, email);
};

export const confirmThePasswordReset = async (
  oobCode: string,
  newPassword: string
) => {
  if (!oobCode && !newPassword) return;

  return await confirmPasswordReset(auth, oobCode, newPassword);
};
