import { Form, Col, Input, Tooltip } from "antd";
import { Field } from "formik";
import TextError from "../TextError/TextError";
import classes from "./CustomTextfield.module.scss";
import { COLORS, translate } from "../../../utils";
import { useState } from "react";
import { InfoCircle } from "iconsax-react";
interface CustomTextFieldProps {
  label: string;
  name: string;
  message?: string;
  required?: boolean;
  toolTip?: string;
  [x: string]: any;
}

export const CustomTextField: React.FC<CustomTextFieldProps> = ({
  name,
  label,
  required,
  message,
  toolTip,
}) => {
  const [inputValue, setInputValue] = useState("");
  return (
    <Form.Item
      label={translate(label)}
      name={name}
      rules={[{ required: required, message: message }]}
      style={{ width: "100%" }}
    >
      <Input
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        type="text"
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: "transparent",
        }}
        suffix={
          <Tooltip title={toolTip}>
            <InfoCircle color="#666666" size={16} />
          </Tooltip>
        }
      />
    </Form.Item>
  );
};

export default CustomTextField;
