import { Col, Form, Input } from "antd";
import { Field } from "formik";
import React from "react";
import TextError from "../TextError/TextError";
import classes from "./CustomTextArea.module.scss";
import { translate } from "../../../utils";

interface CustomTextAreaProps {
  name: string;
  label: string;
  required?: boolean;
  message?: string;
  place?: string;
  placeholder?: string;
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  name,
  label,
  required,
  message,
  placeholder,
}) => {
  return (
    <>
      <Form.Item
        name={name}
        label={translate(label)}
        rules={[
          {
            required: required,
            message: message,
          },
        ]}
        className={classes.inputContainer}
      >
        <Input.TextArea
          style={{
            padding: "8px 16px",
            width: "100%",
            fontSize: "16px",
            minHeight: "40px",
            borderRadius: "4px",
            maxHeight: "200px",
          }}
          placeholder={placeholder}
        />
      </Form.Item>
      {/* <label htmlFor={name} className={classes.label}>
        {translate(label)}{" "}
        {required ? (
          <span className={classes.requiredSpan}>*</span>
        ) : (
          <span className={classes.optionalSpan}>
            ({translate("Optional")})
          </span>
        )}
      </label>{" "}
      <Field
        as={Input.TextArea}
        name={name}
        id={name}
        style={{
          padding: "8px 16px",
          width: "100%",
          fontSize: "16px",
          minHeight: "40px",
          borderRadius: "4px",
          // height: "40px",
          maxHeight: "200px",
        }}
      />
      <TextError name={name} /> */}
    </>
  );
};

export default CustomTextArea;
