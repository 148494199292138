import { ErrorMessage, Field, useField } from "formik";
import React, { MouseEventHandler, useState } from "react";
import "./AppInput.scss";

interface InputComponentProps {
  type: string;
  id: string;
  className?: string;
  errorComponent?: string;
  errorClassName?: string;
  label?: string;
  icon?: React.ReactNode;
  iconOnClick?: MouseEventHandler<HTMLDivElement>;
  fieldName: string;
}

const AppInput: React.FC<InputComponentProps> = ({
  type,
  id,
  className,
  errorComponent,
  errorClassName,
  label,
  icon,
  iconOnClick,
  fieldName,
}) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(fieldName);
  const { touched, error } = meta;
  const [isFocused, setIsFocused] = useState(false);
  const showError = touched && error;

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleChange = (e: any) => {
    helpers.setValue(e.target.value);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <div className="form-group ">
      <label
        htmlFor={id}
        className="rtl-label"
        style={{ color: isFocused ? "#3B4EC4" : "inherit" }}
      >
        {label}
      </label>
      <div className={"input-wrapper"}>
        <Field
          type={type}
          id={id}
          name={fieldName}
          className={className}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          style={{
            border: showError
              ? "1px solid red"
              : isFocused
              ? "1px solid #3B4EC4"
              : "0",
          }}
        />
        <div onClick={iconOnClick} className={"icon-wrapper"}>
          {icon}
        </div>
      </div>
      <ErrorMessage
        name={id}
        component={errorComponent}
        className={errorClassName}
      />
    </div>
  );
};
export default AppInput;
