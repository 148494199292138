import { Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";
import classes from "./AppBreadcrumb.module.scss";
import { ArrowLeft2 } from "iconsax-react";
import { breadcrumbNameMap } from "../../utils/utils";

const AppBreadcrumb = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const isActive = index === pathSnippets.length - 1;
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    const breadcrumbTitle =
      index === 1 ? (
        <span className={classes.link}>{breadcrumbNameMap[url]}</span>
      ) : (
        <Link
          className={`${classes.link} ${isActive && classes.active}`}
          to={url}
        >
          {breadcrumbNameMap[url]}
        </Link>
      );
    return {
      key: url,
      title: breadcrumbTitle,
    };
  });

  return (
    <div>
      <Breadcrumb
        items={extraBreadcrumbItems}
        separator={<ArrowLeft2 size="16" color="#999999" />}
      />
    </div>
  );
};
export default AppBreadcrumb;
