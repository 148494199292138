import { Form, Formik, FormikHelpers } from "formik";
import { Eye, EyeSlash, Sms } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../assets";
import { handleSignIn, handleSignOut } from "../../services";
import AppInput from "../AppInput/AppInput";
import "./LoginForm.scss";
import { loginValidationSchema, translate } from "../../utils";
import FormBackground from "./FormBackground";
import { Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state";

import { authAfterFirebase, setIsLoadingFalse } from "../../state/slices/auth";
import { ThunkDispatch } from "redux-thunk";
import { setLoadingFalse } from "../../state/slices/user";

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {
  // state properties
  const [isLoading, setIsLoading] = useState(false);
  const { isLoading: serverLoading } = useSelector(
    (state: RootState) => state.auth,
  );
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();

  const navigation = useNavigate();

  // form handling
  const [passwordVisible, setPasswordVisible] = useState(false);
  const initialValues: LoginFormValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (
    values: LoginFormValues,
    { setSubmitting }: FormikHelpers<LoginFormValues>,
  ) => {
    message.loading("جاري تسجيل الدخول");
    try {
      setIsLoading(true);
      setSubmitting(true);
      await handleSignIn(values.email, values.password)
        .then(async ({ tokenId }) => {
          if (tokenId) {
            await dispatch(authAfterFirebase(tokenId || ""));
          }
        })
        .then(async () => {
          message.destroy();
          navigation("/dashboard");
        });
    } catch (error: any) {
      message.destroy();
      const errorText = error.code.split("/")[1].replaceAll("-", " ");
      message.error(errorText);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(setLoadingFalse());
    dispatch(setIsLoadingFalse());
    return () => {
      dispatch(setIsLoadingFalse());
      dispatch(setLoadingFalse());
    };
  }, [dispatch]);

  return (
    <div className="container">
      <FormBackground />
      <div className="form-container">
        <div className="logo-title">
          <img src={IMAGES.LOGO_BRAND} className="logo" alt="logo" />
        </div>
        <div className="title-subtitle">
          <h5 className="title">{translate("LOGIN")}</h5>
          <small className="subtitle">{translate("LOGIN_TO_DASHBOARD")}</small>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={loginValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form className="login-form">
            <AppInput
              type={"email"}
              id={"email"}
              fieldName="email"
              className={"form-control"}
              errorComponent={"div"}
              errorClassName={"error-message"}
              label={translate("USER_NAME")}
              icon={<Sms size={26} color={"#c5ccd3"} variant={"Bold"} />}
            />
            <AppInput
              fieldName="password"
              type={passwordVisible ? "text" : "password"}
              id={"password"}
              className={"form-control"}
              errorComponent={"div"}
              errorClassName={"error-message"}
              label={translate("PASSWORD")}
              icon={
                passwordVisible ? (
                  <Eye size="26" color="#c5ccd3" variant="Bold" />
                ) : (
                  <EyeSlash size="26" color="#c5ccd3" variant="Bold" />
                )
              }
              iconOnClick={() => {
                setPasswordVisible((prev) => !prev);
              }}
            />
            {/* forget password ui? */}
            <div className={"forgot-password"}>
              <p
                onClick={() => {
                  handleSignOut();
                  // dispatch(logout());
                }}
              >
                {translate("FORGOT_PASSWORD")}
              </p>
            </div>
            <Button
              htmlType="submit"
              loading={serverLoading || isLoading}
              className="submit-button"
            >
              {translate("LOGIN")}
            </Button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
export default LoginForm;
