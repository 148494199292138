import React from "react";
import { InputSections } from "../../models";
import { Col, Divider, Form, Input, Row, Tooltip } from "antd";
import classes from "../../pages/AddNewDriver/AddNewDriver.module.scss";
import { InfoCircle } from "iconsax-react";
import { FormInputSection } from "../FormInputSection";
import { translate } from "../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../state";

type GenerateFormsProps = {
  data: InputSections;
};
const GenerateForms: React.FC<GenerateFormsProps> = ({ data }) => {
  const { selectedLanguage } = useSelector(
    (state: RootState) => state.language
  );
  const getLanguage = () => {
    if (selectedLanguage === "en") {
      return "Enter";
    } else {
      return "ادخل";
    }
  };

  return (
    <>
      {data.map((section: any) => (
        <FormInputSection
          key={section.step}
          step={section.step}
          title={translate(section.title)}
        >
          <Row gutter={24}>
            {section.children.map((children: any) => (
              <Col
                key={children.id}
                xl={8}
                md={12}
                sm={12}
                xs={24}
                style={{ paddingInline: "10px" }}
              >
                <Form.Item
                  label={translate(children.label)}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className={classes.label}
                >
                  <Input
                    placeholder={`${getLanguage()} ${translate(
                      children.label
                    )}`}
                    className={classes.input}
                    suffix={
                      <Tooltip
                        title={`${getLanguage()} ${translate(children.label)}`}
                      >
                        <InfoCircle color="#666666" size={16} />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Divider />
        </FormInputSection>
      ))}
    </>
  );
};

export default GenerateForms;
