import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { useDispatch } from "react-redux";
import { changeSessionTimeout, logout } from "../state/slices/auth";
import { message } from "antd";

const useAxios = (
  baseUrl: string
): [
  AxiosInstance,
  (config: AxiosRequestConfig) => Promise<AxiosResponse<any>>,
] => {
  const dispatch = useDispatch();

  const api = axios.create({
    baseURL: baseUrl,
    params: {
      pageSize: 10000,
    },
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Expose-Headers": "Set-Cookie",
    },
    withCredentials: true,
  });
  const onRequest = (config: any) => {
    return config;
  };
  const onRequestError = (error) => {
    return Promise.reject(error);
  };

  const onResponse = (response: any) => {
    changeSessionTimeout(response.headers["x-digimile-session-expires-at"]);
    return response;
  };

  const onResponseError = (error: AxiosError) => {
    if (error?.response?.status === 401) {
      dispatch(logout());
      window.location.href = "/login";
    }
    if (error.code === "ERR_NETWORK") {
      return message.error(error.message);
    }
    // return Promise.reject(error);
  };
  api.interceptors.request.use(onRequest, onRequestError);
  api.interceptors.response.use(onResponse, onResponseError);
  api.defaults.withCredentials = true;

  const request = async (config: AxiosRequestConfig) => {
    try {
      const response: AxiosResponse<any> = await api(config);
      return response;
    } catch (err: any) {
      throw new Error(err.message);
    }
  };

  return [api, request];
};

export default useAxios;
