import { combineReducers } from "redux";

import user from "./user";
import language from "./language";
import auth from "./auth";
import settings from "./settings";

export const reducers = combineReducers({
  user,
  language,
  auth,
  settings,
});
