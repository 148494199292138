import ReactDOM from "react-dom/client";
import AppInitializer from "./AppInitializer";
import "./index.scss";
import "./normalize.css";
import { Provider } from "react-redux";
import { persistor, store } from "./state";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppInitializer />
    </PersistGate>
  </Provider>
);
