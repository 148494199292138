/* eslint-disable */

import {
  Discover,
  DocumentText1,
  Gps,
  Logout,
  People,
  RouteSquare,
  Setting2,
  Ship,
} from "iconsax-react";
import { getItem, translate } from "../utils";

import { MenuProps } from "antd";
import { paths } from "../utils/utils";
import i18n from "../localization";

export type MenuItem = Required<MenuProps>["items"][number];

const urlObj = new URL(location.href);

export const navigationItems: MenuItem[] = [
  getItem(
    translate("Dashboard"),
    paths.dashboard,
    <Discover color="#292d32" />
  ),
  getItem(
    "الشاشات الرئيسية",
    "grp2",
    null,
    [
      getItem(
        translate("Quick Order"),
        paths.quickOrder,
        <Ship color="#292d32" />
      ),
      getItem(
        translate("Tracking"),
        paths.tracking,
        <Gps
          variant={urlObj.pathname === paths.tracking ? "Bold" : "Outline"}
          color={urlObj.pathname === paths.tracking ? "#3b4ec4" : "#292d32"}
        />
      ),
      getItem(
        translate("Users"),
        paths.users,
        <People
          size="32"
          color={urlObj.pathname === paths.tracking ? "#3b4ec4" : "#292d32"}
        />
      ),
    ],
    "group"
  ),

  // getItem(
  //   translate("TRIPS_AND_ORDERS"),
  //   "trips-and-orders",
  //   <RouteSquare color="#292d32" />,
  //   [
  //     getItem(
  //       translate("CREATE_NEW_COMPANY"),
  //       paths.tripsAndOrders.createNewCompany
  //     ),
  //     getItem(translate("ADD_NEW_MANAGER"), paths.tripsAndOrders.createManager),
  //     getItem(translate("ADD_NEW_DRIVER"), paths.tripsAndOrders.addDriver),
  //     getItem(
  //       translate("SYSTEM_SETTINGS"),
  //       paths.tripsAndOrders.systemSettings
  //     ),
  //   ]
  // ),
  // getItem(
  //   translate("USERS_AND_ROLES"),
  //   "/usersAndTurns",
  //   <People color="#292d32" />,
  //   [
  //     getItem(
  //       translate("CREATE_NEW_COMPANY"),
  //       paths.tripsAndOrders.createNewCompany
  //     ),
  //     getItem(translate("ADD_NEW_MANAGER"), paths.tripsAndOrders.createManager),
  //     getItem(translate("ADD_NEW_DRIVER"), paths.tripsAndOrders.addDriver),
  //     getItem(
  //       translate("SYSTEM_SETTINGS"),
  //       paths.tripsAndOrders.systemSettings
  //     ),
  //   ]
  // ),
  // getItem(translate("TRACKING"), "/tracking", <Gps color="#292d32" />, [
  //   getItem(
  //     translate("CREATE_NEW_COMPANY"),
  //     paths.tripsAndOrders.createNewCompany
  //   ),
  //   getItem(translate("ADD_NEW_MANAGER"), paths.tripsAndOrders.createManager),
  //   getItem(translate("ADD_NEW_DRIVER"), paths.tripsAndOrders.addDriver),
  //   getItem(translate("SYSTEM_SETTINGS"), paths.tripsAndOrders.systemSettings),
  // ]),
  // getItem(translate("REPORTS"), "/reports", <DocumentText1 color="#292d32" />, [
  //   getItem(
  //     translate("CREATE_NEW_COMPANY"),
  //     paths.tripsAndOrders.createNewCompany
  //   ),
  //   getItem(translate("ADD_NEW_MANAGER"), paths.tripsAndOrders.createManager),
  //   getItem(translate("ADD_NEW_DRIVER"), paths.tripsAndOrders.addDriver),
  //   getItem(translate("SYSTEM_SETTINGS"), paths.tripsAndOrders.systemSettings),
  // ]),
  getItem(
    "الشاشات الفرعية",
    "grp",
    null,
    [
      getItem(
        translate("DASHBOARD_SETTINGS"),
        paths.dashboardSettings,
        <Setting2 color="#292d32" />
      ),
      getItem(translate("LOGOUT"), "logout", <Logout color="#292d32" />),
    ],
    "group"
  ),
];

export const companyDetailsInputs = [
  {
    id: 0,
    label: "اسم الشركة",
    type: "text",
    placeholder: "ادخل اسم الشركة",
    tip: "ادخل اسم الشركة باللغة العربية",
  },
  {
    id: 1,
    label: "رقم السجل",
    type: "text",
    placeholder: "ادخل رقم السجل",
    tip: "ادخل رقم السجل",
  },
  {
    id: 2,
    label: "الرقم الضريبي",
    type: "text",
    placeholder: "ادخل الرقم الضريبي",
    tip: "ادخل الرقم الضريبي",
  },
  {
    id: 3,
    label: "رقم التسجيل الضريبي",
    type: "text",
    placeholder: "ادخل رقم التسجيل الضريبي",
    tip: "ادخل رقم التسجيل الضريبي",
  },
  {
    id: 4,
    label: "نوع العمل",
    type: "text",
    placeholder: "ادخل نوع العمل",
    tip: "ادخل نوع العمل",
  },
  {
    id: 5,
    label: "العنوان",
    type: "text",
    placeholder: "ادخل العنوان",
    tip: "ادخل العنوان",
  },
  {
    id: 6,
    label: "جهات الاتصال",
    type: "text",
    placeholder: "ادخل اسم الشركة",
    tip: "ادخل اسم الشركة",
  },
  {
    id: 7,
    label: "رقم المحمول",
    type: "text",
    placeholder: "ادخل رقمك المحمول",
    tip: "ادخل رقمك المحمول",
  },
  {
    id: 8,
    label: "انواع الخدمة",
    type: "text",
    placeholder: "ادخل انواع الخدمه",
    tip: "ادخل انواع الخدمة",
  },
];
export const companyContractData = [
  {
    id: 0,
    label: "الدفعة المقدمة",
    type: "text",
    placeholder: "ادخل الدفعة المقدمة",
    tip: "ادخل الدفعة المقدمة",
  },
  {
    id: 1,
    label: "نوع العقد",
    type: "text",
    placeholder: "ادخل نوع العقد",
    tip: "ادخل نوع العقد",
  },
  {
    id: 2,
    label: "رقم العقد",
    type: "text",
    placeholder: "شروط الدفع",
    tip: "ادخل شروط الدفع",
  },
  {
    id: 3,
    label: "شروط الدفع",
    type: "text",
    placeholder: "ادخل شروط الدفع",
    tip: "ادخل شروط الدفع",
  },
  {
    id: 4,
    label: "نوع العقد للضرائب",
    type: "text",
    placeholder: "ادخل نوع العقد",
    tip: "ادخل نوع العقد",
  },
];
export const driverMainDetails = [
  {
    id: 0,
    label: "الإسم بالكامل",
    type: "text",
    placeholder: "ادخل إسم السائق",
    tip: "ادخل اسم السائق باللغة العربية",
  },
  {
    id: 1,
    label: "رقم الجوال",
    type: "text",
    placeholder: "ادخل رقم الجوال",
    tip: "ادخل رقم الجوال",
  },
  {
    id: 2,
    label: "عنوان السائق",
    type: "text",
    placeholder: "ادخل عنوان السائق",
    tip: "ادخل عنوان السائق",
  },
  {
    id: 3,
    label: "رقم المحمول",
    type: "text",
    placeholder: "ادخل رقمك المحمول",
    tip: "ادخل رقمك المحمول",
  },
  {
    id: 4,
    label: "الرقم القومي",
    type: "text",
    placeholder: "ادخل الرقم القومي",
    tip: "ادخل الرقم القومي",
  },
  {
    id: 5,
    label: "صورة البطاقة",
    type: "text",
    placeholder: "ادخل صورة البطاقة",
    tip: "ادخل صورة البطاقة",
  },
  {
    id: 6,
    label: "آخر حالة إجرامية",
    type: "text",
    placeholder: "ادخل آخر حالة إجرامية",
    tip: "ادخل آخر حالة إجرامية",
  },
];
export const licenseDetails = [
  {
    id: 0,
    label: "نوع رخصة القيادة",
    type: "text",
    placeholder: "ادخل نوع رخصة القيادة",
    tip: "ادخل نوع رخصة القيادة",
  },
  {
    id: 1,
    label: "رقم رخصة القيادة",
    type: "text",
    placeholder: "ادخل رقم رخصة القيادة",
    tip: "ادخل رقم رخصة القيادة",
  },
  {
    id: 2,
    label: "صلاحية رخصة القيادة",
    type: "text",
    placeholder: "ادخل صلاحية رخصة القيادة",
    tip: "ادخل صلاحية رخصة القيادة",
  },
  {
    id: 3,
    label: "حالة الخدمة العسكرية",
    type: "text",
    placeholder: "ادخل حالة الخدمة العسكرية",
    tip: "ادخل حالة الخدمة العسكرية",
  },
];
export const truckDetails = [
  {
    id: 0,
    label: "اسم الشركة",
    type: "text",
    placeholder: "ادخل اسم الشركة",
    tip: "ادخل اسم الشركة",
  },
  {
    id: 1,
    label: "موقع المرآب",
    type: "text",
    placeholder: "ادخل موقع المرآب",
    tip: "ادخل موقع المرآب",
  },
  {
    id: 2,
    label: "ميناء العمل",
    type: "text",
    placeholder: "ادخل ميناء العمل",
    tip: "ادخل ميناء العمل",
  },
  {
    id: 3,
    label: "حالة رخصة رأس الشاحنة",
    type: "text",
    placeholder: "ادخل رخصة رأس الشاحنة",
    tip: "ادخل رخصة رأس الشاحنة",
  },
  {
    id: 4,
    label: "رخصة ذيل الشاحنة",
    type: "text",
    placeholder: "ادخل رخصة ذيل الشاحنة",
    tip: "ادخل رخصة ذيل الشاحنة",
  },
  {
    id: 5,
    label: "نوع العقد",
    type: "text",
    placeholder: "ادخل نوع العقد",
    tip: "ادخل نوع العقد",
  },
  {
    id: 6,
    label: "الأسعار",
    type: "text",
    placeholder: "ادخل الأسعار",
    tip: "ادخل الأسعار",
  },
  {
    id: 7,
    label: "شروط الدفع",
    type: "text",
    placeholder: "ادخل شروط الدفع",
    tip: "ادخل شروط الدفع",
  },
  {
    id: 8,
    label: "نوع العقد للضرائب",
    type: "text",
    placeholder: "ادخل نوع العقد للضرائب",
    tip: "ادخل نوع العقد للضرائب",
  },
];
export const addressDetails = [
  {
    id: 0,
    label: "اسم الموقع",
    type: "text",
    placeholder: "ادخل اسم الموقع",
    tip: "ادخل اسم الموقع",
  },
  {
    id: 1,
    label: "وصف / النوع",
    type: "text",
    placeholder: "ادخل وصف / النوع",
    tip: "ادخل وصف / النوع",
  },
  {
    id: 2,
    label: "المنطقة",
    type: "text",
    placeholder: "ادخل المنطقة",
    tip: "ادخل المنطقة",
  },
  {
    id: 3,
    label: "المدينة",
    type: "text",
    placeholder: "ادخل المدينة",
    tip: "ادخل المدينة",
  },
  {
    id: 4,
    label: "خط الطول",
    type: "text",
    placeholder: "ادخل خط الطول",
    tip: "ادخل خط الطول",
  },
  {
    id: 5,
    label: "خط العرض",
    type: "text",
    placeholder: "ادخل خط العرض",
    tip: "ادخل خط العرض",
  },
];

export const addNewDriverPage = [
  { step: 1, title: "بيانات السائق الأساسية", children: driverMainDetails },
  { step: 2, title: "بيانات الرخصة", children: licenseDetails },
  { step: 3, title: "بيانات الشاحنة", children: truckDetails },
  { step: 4, title: "بيانات العنوان", children: addressDetails },
];
export const addNewCompanyPage = [
  { step: 1, title: "البيانات الأساسية", children: companyDetailsInputs },
  { step: 2, title: "بيانات العقد", children: companyContractData },
];
