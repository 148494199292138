import React from "react";
import classes from "./FormBackground.module.scss";

const FormBackground = () => (
  <div className={classes.backgroundContainer}>
    <div className={classes.divOne} />
    <div className={classes.divTwo} />
    <div className={classes.divThree} />
    <div className={classes.divFour} />
    <div className={classes.divFive} />
  </div>
);

export default FormBackground;
