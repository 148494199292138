import { useSelector } from "react-redux";
import useAxios from "../../hooks/useAxios";
import { Button, Col, Form, Row } from "antd";
import { CustomTextField, PhoneField } from "../../components";
import { User } from "../../models/user";
import { RootState } from "../../state";
import styles from "./CreateUserBP.module.scss";
import { CheckboxList } from "../../components/FormikFields/CheckboxList";

const CreateUserBP = () => {
  const { user }: any = useSelector((state: RootState) => state.auth);

  const [request] = useAxios(
    process.env.REACT_APP_DIGIMILE_PLATFORM_BASE_URL as string
  );

  const initialValues: User = {
    name: "",
    loginEmail: "",
    phoneNumber: "",
    roles: [],
  };
  
  const onFinish = async (values: Partial<User>) => {
    console.log(values);
    try {
      const response = await request({
        method: "POST",
        url: `/api/${user?.tenants?.[0]?.tenantId}/users`,
        data: values,
        withCredentials: true,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles["create-user"]}>
      <div className={styles["create-user__title"]}>
        <p>قم بإنشاء عميل جديد</p>
      </div>
      <Form
        onFinish={onFinish}
        initialValues={initialValues}
        layout="vertical"
        autoComplete="off"
        className={styles["create-user__form"]}
      >
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <CustomTextField
              name="name"
              label="الاسم"
              required
              message="هذا الحقل مطلوب"
              toolTip="الاسم"
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <CustomTextField
              name="loginEmail"
              label="البريد الالكتروني"
              message="هذا الحقل مطلوب"
              toolTip="البريد الالكتروني"
              required
            />
          </Col>
          <Col xs={24} md={12}>
            <PhoneField
              name="phoneNumber"
              label="رقم الهاتف"
              required
              message="هذا الحقل مطلوب"
              placeholder="رقم الهاتف"
            />
          </Col>
        </Row>
        <Col xs={24} md={12}>
            <CheckboxList name = "roles"/>
        </Col>
        <Button type="primary" htmlType="submit">
          تأكيد
        </Button>
      </Form>
    </div>
  );
};

export default CreateUserBP;
