import { Layout, Menu } from "antd";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { navigationItems } from "../../constants/dummy";
import classes from "./Navigation.module.scss";
import { useDispatch } from "react-redux";
import { logout } from "../../state/slices/auth";
import { handleSignOut } from "../../services";

const { Sider } = Layout;

type navigationProps = {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  noCollapse: boolean;
};
const Navigation = ({
  collapsed,
  setCollapsed,
  noCollapse,
}: navigationProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const defaultSelectedKeys = pathname.split("/").filter((part) => part !== "");

  const collapseInMobileHandler = () => {
    if (noCollapse) return setCollapsed(true);
  };
  useEffect(() => {
    collapseInMobileHandler();
  }, []);

  const dispatch = useDispatch();

  const handleLogout = () => {
    handleSignOut();
    dispatch(logout());
    window.location.href = "/login";
  };

  return (
    <Sider
      collapsible
      trigger={null}
      collapsed={collapsed}
      className={`${classes.sideMenu} ${
        collapsed && classes.sideMenuCollapsed
      }`}
    >
      <Menu
        mode="inline"
        defaultOpenKeys={[defaultSelectedKeys[1]]}
        selectedKeys={[pathname]}
        items={navigationItems}
        onClick={({ key }) => {
          if (key === "logout") return handleLogout();
          navigate(key);
        }}
        className={classes.navigationContainer}
      />
    </Sider>
  );
};
export default Navigation;
