import { Col, DatePicker, Form } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { translate } from "../../../utils";
import classes from "./CustomDatePicker.module.scss";

interface CustomDatePickerProps {
  label: string;
  name: string;
  required?: boolean;
  message?: string;
  onChange?: (date: string) => void;
  disableCol?: boolean;
  suffixIcon?: React.ReactNode;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  label,
  name,
  required = false,
  message: warningMessage,
  onChange = () => {},
  disableCol,
  suffixIcon,
}) => {
  const [selectedDate, setSelectedDate] = useState(Date());

  const handleDateChange = (date, dateString) => {
    //TODO: add placed date value to compare..
    // if (name === "scheduledDate") {
    //   let date1 = moment(date);
    //   let date2 = moment(values.placedDate);
    //   if (date1.isBefore(date2)) {
    //     message.error("اختر تاريخ بعد تاريخ الطلب");
    //     return;
    //   } else if (date1.isAfter(date2)) {
    //     setSelectedDate(date);
    //   }
    // } else {
    //   setSelectedDate(dayjs(date).format());
    // }
    setSelectedDate(dateString);
    onChange(dayjs(date).toISOString());
  };

  return (
    <Form.Item
      name={name}
      label={translate(label)}
      rules={[
        {
          required: required,
          message: warningMessage,
        },
      ]}
    >
      <DatePicker
        format="YYYY-MM-DD HH:mm:ss"
        // value={selectedDate.toString()}
        placeholder={translate(label)}
        onChange={(date, dateString) => handleDateChange(date, dateString)}
        // disabledDate={disabledDate}
        // disabledTime={disabledDateTime}
        showTime={{
          defaultValue: dayjs("00:00:00", "HH:mm:ss"),
        }}
        className={classes.datePicker}
        suffixIcon={suffixIcon}
      />
    </Form.Item>
  );
};

export default CustomDatePicker;
