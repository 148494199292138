import React, { useState } from "react";
import { Checkbox, Form } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";

type CheckboxListProps = {
  name: string;
};
const CheckboxList: React.FC<CheckboxListProps> = ({ name }) => {
  const [checkedValues, setCheckedValues] = useState<CheckboxValueType[]>([]);

  const options = [
    { label: "أدمن", value: "tenant.admin" },
    { label: "مدير الشركة", value: "Trucking.Customer.FleetManager" },
    { label: "سيكيوريتي", value: "option3" },
  ];

  const onChange = (checkedValues: CheckboxValueType[]) => {
    console.log("checked = ", checkedValues);
    setCheckedValues(checkedValues);
  };

  return (
    <Form.Item name={name} label="اختر نوع العميل">
      <Checkbox.Group
        options={options}
        value={checkedValues}
        onChange={onChange}
        style={{ width: "100%" }}
        name={name}
      />
    </Form.Item>
  );
};

export default CheckboxList;
