import { translate } from "./helpers";

export const paths = {
  dashboard: "/dashboard",
  quickOrder: "/dashboard/quick-order",
  users: "/dashboard/users",
  tripsAndOrders: {
    createNewCompany: "/dashboard/trips-and-orders/create-company",
    createManager: "/dashboard/trips-and-orders/create-responsible-manager",
    addDriver: "/dashboard/trips-and-orders/add-new-driver",
    systemSettings: "/dashboard/trips-and-orders/system-settings",
  },
  usersAndTurns: {
    createNewCompany: "/dashboard/trips-and-orders/create-company",
    createManager: "/dashboard/trips-and-orders/create-responsible-manager",
    addDriver: "/dashboard/trips-and-orders/add-new-driver",
    systemSettings: "/dashboard/trips-and-orders/system-settings",
  },
  tracking: "/dashboard/tracking",
  reports: {
    createNewCompany: "/dashboard/trips-and-orders/create-company",
    createManager: "/dashboard/trips-and-orders/create-responsible-manager",
    addDriver: "/dashboard/trips-and-orders/add-new-driver",
    systemSettings: "/dashboard/trips-and-orders/system-settings",
  },
  dashboardSettings: "/dashboard/settings",
};

export const breadcrumbNameMap: Record<string, string> = {
  "/dashboard": translate("DASHBOARD"),
  "/dashboard/trips-and-orders": translate("TRIPS_AND_ORDERS"),
  "/dashboard/trips-and-orders/create-company": translate("CREATE_NEW_COMPANY"),
  "/dashboard/trips-and-orders/add-new-driver": translate("ADD_NEW_DRIVER"),
  "/dashboard/tracking": translate("Tracking"),
};

export const prettifyObject = (object) => {
  const prettiedObject = {};
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const desiredProperty = object[key];
      if (desiredProperty != null) {
        prettiedObject[key] = desiredProperty;
      }
    }
  }
  return prettiedObject;
};

export const generateRandomString = (length: number) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};
