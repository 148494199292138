import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";

export interface TUser {
  userId: string;
  name: string;
  email: string;
  phone: string;
  isEmailVerified: boolean;
  pictureUrl: string;
  customClaims: CustomClaims;
  tenants?: TenantsEntity[] | never[] | null;
}
export interface CustomClaims {}
export interface TenantsEntity {
  tenantId: string;
  tenantName: string;
  roles?: string[] | null;
}

export interface AuthState {
  isAuthenticated: boolean | undefined;
  isLoading: boolean;
  error: object | null;
  user: TUser | null;
  sessionTimeout: any | null;
}

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem("isAuthenticated"),
  isLoading: false,
  error: null,
  sessionTimeout: null,
  user: {
    userId: "",
    name: "",
    email: "",
    pictureUrl: "",
    customClaims: "",
    isEmailVerified: false,
    phone: "",
    tenants: [],
  },
};

export const authAfterFirebase = createAsyncThunk(
  "auth/authenticate",
  async (idToken: string, { rejectWithValue }) => {
    try {
      const { data, headers } = await axios.post(
        `${process.env.REACT_APP_DIGIMILE_PLATFORM_BASE_URL}/api/platform/session/login`,
        {
          idToken,
        },
        {
          withCredentials: true,
        }
      );
      const sessionTimeout = await headers["x-digimile-session-expires-at"];
      return { data, sessionTimeout };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state) => {
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.sessionTimeout = null;
      Cookies.remove("");
    },
    changeSessionTimeout: (state, action) => {
      state.sessionTimeout = action.payload;
    },
    setIsLoadingFalse: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authAfterFirebase.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(authAfterFirebase.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload?.data;
      state.sessionTimeout = action.payload.sessionTimeout
        ? moment(action.payload.sessionTimeout)
        : moment().add(10, "minutes");
    });
    builder.addCase(authAfterFirebase.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const { setIsLoadingFalse, logout, changeSessionTimeout } =
  authSlice.actions;
export default authSlice.reducer;
