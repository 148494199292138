import moment from "moment";
import { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../../state";

interface Props {
  children: ReactElement;
}

export const ProtectedRoute = ({ children }: Props) => {
  const { sessionTimeout } = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  const timeDiffer = moment().isBefore(sessionTimeout);

  if (!timeDiffer) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export const AuthRoute = ({ children }: Props) => {
  const { sessionTimeout }: any = useSelector((state: RootState) => state.auth);
  const timeDiffer = moment().isBefore(sessionTimeout);

  if (timeDiffer) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

export const MainRoute = ({ children }: Props) => {
  const { sessionTimeout }: any = useSelector((state: RootState) => state.auth);
  const timeDiffer = moment().isBefore(sessionTimeout);

  if (!timeDiffer) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
};
