import React from "react";
import classes from "./SectionHeader.module.scss";
import { Typography } from "antd";

type SectionHeaderProps = {
  title: string;
  children?: React.ReactNode;
};

 const SectionHeader = ({ title, children }: SectionHeaderProps) => {
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      {children}
    </div>
  );
};
export default SectionHeader;