import React, { createContext, ReactNode, useState } from "react";
import { SplashScreen } from "../components/SplashScreen/SplashScreen";

interface LoadingContextProps {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const LoadingContext = createContext<LoadingContextProps>({
  isLoading: false,
  setIsLoading: () => {},
});

interface LoadingProviderProps {
  children: ReactNode;
}

export const LoadingProvider: React.FC<LoadingProviderProps> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const setLoading = (isLoading: boolean) => {
    setIsLoading(isLoading);
  };

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading: setLoading }}>
      {isLoading && <SplashScreen />}
      {children}
    </LoadingContext.Provider>
  );
};
