import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Header, Navigation } from "../components";
import { RootState } from "../state";
import { toggleCollapseMenu } from "../state/slices/settings";
import classes from "./DashboardLayout.module.scss";

const { Content } = Layout;

const DashboardLayout = () => {
  const noCollapse = window.innerWidth <= 786;
  const { menuCollapsed: collapsed } = useSelector(
    (state: RootState) => state.settings
  );
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();

  const setCollapsed = () => {
    dispatch(toggleCollapseMenu());
  };

  return (
    <>
      <Header
        collapsed={collapsed}
        noCollapse={noCollapse}
        setCollapsed={setCollapsed}
      />
      <Layout className={classes.container}>
        <Navigation
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          noCollapse={noCollapse}
        />
        <Layout className={classes.main}>
          <Outlet />
        </Layout>
      </Layout>
    </>
  );
};
export default DashboardLayout;
